import './Wedding.css'
import { languages } from '../../constants/ImportPhoto'
import Photo from './Photo/Photo'
import Series from './Series/Series'
import WeddingBooks from './Books/WeddingBooks'

const Wedding = () => {
  return (
    <div>
      <div className="Wedding">
        <Series lang={languages} />
        <Photo lang={languages} />
        <WeddingBooks />
      </div>
    </div>
  )
}

export default Wedding
