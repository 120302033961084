import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import './utils.css'

export default function LongContent(props) {
  const [visible, setVisible] = useState(false)
    const [imgOpen01, setimgOpen01] = useState('Long-Poto-Start')
    useEffect(() => {
    setimgOpen01('Long-Poto')
    }, [])
  return (
    <div className="card flex justify-content-center">
      <div className="Galleri-menu">
        <div
          className={imgOpen01}
          onClick={() => {
            setVisible(true)
            props.gall.number = 0
          }}
        >
          <img src={props.gall.photoIcon[0]} alt="" />
        </div>
        <div
          className={imgOpen01}
          onClick={() => {
            setVisible(true)
            props.gall.number = 1
          }}
        >
          <img src={props.gall.photoIcon[1]} alt="" />
        </div>
        <div
          className={imgOpen01}
          onClick={() => {
            setVisible(true)
            props.gall.number = 2
          }}
        >
          <img src={props.gall.photoIcon[2]} alt="" />
        </div>
        <div
          className={imgOpen01}
          onClick={() => {
            setVisible(true)
            props.gall.number = 3
          }}
        >
          <img src={props.gall.photoIcon[3]} alt="" />
        </div>
      </div>
      <Dialog
        header={props.gall.header[props.gall.number]}
        visible={visible}
        style={{ width: '100vw', maxWidth: `${props.gall.width}` }}
        onHide={() => setVisible(false)}
      >
        {props.gall.galleria[props.gall.number].map((item, index) => (
          <div key={index}>
            <img
              src={item}
              style={{ width: '100vw', maxWidth: `${props.gall.widthCont}` }}
              alt=""
            />
            <p className="mb-5"></p>
          </div>
        ))}
      </Dialog>
    </div>
  )
}
