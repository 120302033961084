import './Books.css'
import LongContent from '../../../utils/LongContent'
import { galleriaBooks } from '../../../constants/ImportPhoto'

export default function WeddingBooks() {
  return (
    <div>
      <div className="Wedding_Book">
        <div className="Book-header PhotoWedding"></div>
        <div className="Description">
          <h1>Свадебные фотокниги.</h1>
          <h2>
            Все успешно отснятые фотографии по окончанию фотосьемки
            обрабатываться, в течении 30 дней, и затем передаются вам в
            электронном виде любую из них вы сами при необходимости можете
            распечатать в нужном вам формате и количестве. Но можно включить в
            услугу разработку дизайна, верстку и печать свадебной фотокниги.
            Имея фотокнигу, вы получаете возможность вспомнить о знаменательном
            для вас дне просто полистав ее, сами или вместе с близкими людьми.
            Можно изготовить несколько экземпляров и подарить на память
            родителям. Стоимость от 25 у.е. Ниже примеры разворотов фотокниг.
          </h2>
        </div>
        <LongContent gall={galleriaBooks} />
      </div>
    </div>
  )
}
