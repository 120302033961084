import './Series.css'
import LongContent from '../../../utils/LongContent'
import { galleriaSeries } from '../../../constants/ImportPhoto'

const Series = () => {
  return (
    <div>
      <div className="Wedding_Сell">
        <div className="Series PhotoWedding"></div>
        <div className="Description">
          <h1>Свадебная фотосьемка</h1>
          <h2>
            День свадьбы — это череда событий, начиная от утренних сборов
            невесты и заканчивая веселым застольем до позднего вечера. Все
            события окрашены яркими эмоциями. Моя работа сохранить для вас самые
            важные и волнительные моменты этого дня. Вы сами определяете время,
            в течении которого вам нужна моя помощь. Это может быть всего один
            час, а может и весь свадебный день, определяете вы. Стоимость от 50
            у.е. Посмотрите ниже несколько серий фотографий, если вас
            заинтересует, позвоните или напишите мне, пообщаемся, а выбор
            останется за вами.
          </h2>
        </div>
        <LongContent gall={galleriaSeries} />
      </div>
    </div>
  )
}

export default Series
