import seria2022 from '../assets/Series/2022seria.jpg'
import seria2023 from '../assets/Series/2023seria.jpg'
import seria2017 from '../assets/Series/2017seria.jpg'
import seria2015 from '../assets/Series/2015seria.jpg'
import Сover2016 from '../assets/Series/Сover2016.jpg'
import Сover2017 from '../assets/Series/Сover2017.jpg'
import Сover2018 from '../assets/Series/Сover2018.jpg'
import Сover2019 from '../assets/Series/Сover2019.jpg'
  // header: ['Reportage', 'Artistic', 'Brest 2017'],
export const languages = [
  'VIKTAR VOLSKI',
  'Фотосъемка',
  'Свадебная',
  'Семейная',
  'Клубная',
  'Контакты',
  'Репортаж',
  'Постановка',
  'Другое',
  'Семейное',
  'Клубная',
  'Объектная',
  'Еда',
  'Фотокниги',
]

export const cities = [
  {
    name: 'EN',
    code: [
      'VIKTAR VOLSKI',
      'Wedding',
      'Photoshoot',
      'Photo',
      'Photobook',
      'Contact',
      'Reportage',
      'Artistic',
      'Other',
      'Family',
      'Club',
      'Object',
      'Food',
      'Other',
    ],
  },
  {
    name: 'PL',
    code: [
      'VIKTAR VOLSKI',
      'Ślub',
      'Sesja',
      'Zdjęcie',
      'Fotoksiążka',
      'Łączność',
      'Reportaż',
      'Inscenizacja',
      'Inny',
      'Rodzina',
      'Klub',
      'Obiekt',
      'Żywność',
      'Inny',
    ],
  },
  {
    name: 'RU',
    code: [
      'VIKTAR VOLSKI',
      'Свадьба',
      'Фотосессия',
      'Фото',
      'Фотокнига',
      'Контакты',
      'Репортаж',
      'Постановка',
      'Другое',
      'Семейное',
      'Клубная',
      'Объект',
      'Еда',
      'Другой',
    ],
  },
]

// const path = 'natasmart.com'
const path = 'volsvik.com'
const pathSeries = `https://${path}/photography/Series/`
const pathGalleris = `https://${path}/photography/Galleris/`
const pathBooks = `https://${path}/photography/Books/`
const pathClub = `https://${path}/photography/Club/`
const pathFamily = `https://${path}/photography/Family/`

export const galleriaEgyptBook = []
for (let i = 0; i < 17; i++) {
  if (i < 9) {
    galleriaEgyptBook.push(`${pathBooks}Egypt/Egypt-0000${i + 1}.jpg`)
  } else {
    galleriaEgyptBook.push(`${pathBooks}Egypt/Egypt-000${i + 1}.jpg`)
  }
}

export const galleriaFamilyBook01 = []
for (let i = 0; i < 27; i++) {
  if (i < 9) {
    galleriaFamilyBook01.push(
      `${pathFamily}Books/FamilyBook01/FamilyBook01-0000${i + 1}.jpg`
    )
  } else {
    galleriaFamilyBook01.push(
      `${pathFamily}Books/FamilyBook01/FamilyBook01-000${i + 1}.jpg`
    )
  }
}

export const galleriaFamilyBook02 = []
for (let i = 0; i < 18; i++) {
  if (i < 9) {
    galleriaFamilyBook02.push(
      `${pathFamily}Books/FamilyBook02/FamilyBook02-0000${i + 1}.jpg`
    )
  } else {
    galleriaFamilyBook02.push(
      `${pathFamily}Books/FamilyBook02/FamilyBook02-000${i + 1}.jpg`
    )
  }
}

export const galleriaFamilyBook03 = []
for (let i = 0; i < 15; i++) {
  if (i < 9) {
    galleriaFamilyBook03.push(
      `${pathFamily}Books/FamilyBook03/FamilyBook03-0000${i + 1}.jpg`
    )
  } else {
    galleriaFamilyBook03.push(
      `${pathFamily}Books/FamilyBook03/FamilyBook03-000${i + 1}.jpg`
    )
  }
}

export const galleriaFamily1000 = []
export const galleriaFamily170 = []
for (let i = 0; i < 54; i++) {
  if (i < 9) {
    galleriaFamily1000.push(
      `${pathFamily}FamilyPhoto/1000/FamilyPhoto-0000${i + 1}.jpg`
    )
    galleriaFamily170.push(
      `${pathFamily}FamilyPhoto/170/FamilyPhoto-0000${i + 1}.jpg`
    )
  } else {
    galleriaFamily1000.push(
      `${pathFamily}FamilyPhoto/1000/FamilyPhoto-000${i + 1}.jpg`
    )
    galleriaFamily170.push(
      `${pathFamily}FamilyPhoto/170/FamilyPhoto-000${i + 1}.jpg`
    )
  }
}

export const galleriaClub1000 = []
export const galleriaClub170 = []
for (let i = 0; i < 62; i++) {
  if (i < 9) {
    galleriaClub1000.push(`${pathClub}1000/Club-0000${i + 1}.jpg`)
    galleriaClub170.push(`${pathClub}170/Club-0000${i + 1}.jpg`)
  } else {
    galleriaClub1000.push(`${pathClub}1000/Club-000${i + 1}.jpg`)
    galleriaClub170.push(`${pathClub}170/Club-000${i + 1}.jpg`)
  }
}

export const galleriaBook2016 = []
for (let i = 0; i < 25; i++) {
  if (i < 9) {
    galleriaBook2016.push(`${pathBooks}2016/Book2016-0000${i + 1}.jpg`)
  } else {
    galleriaBook2016.push(`${pathBooks}2016/Book2016-000${i + 1}.jpg`)
  }
}

export const galleriaBook2017 = []
for (let i = 0; i < 22; i++) {
  if (i < 9) {
    galleriaBook2017.push(`${pathBooks}2017/Book2017-0000${i + 1}.jpg`)
  } else {
    galleriaBook2017.push(`${pathBooks}2017/Book2017-000${i + 1}.jpg`)
  }
}

export const galleriaBook2018 = []
for (let i = 0; i < 30; i++) {
  if (i < 9) {
    galleriaBook2018.push(`${pathBooks}2018/Book2018-0000${i + 1}.jpg`)
  } else {
    galleriaBook2018.push(`${pathBooks}2018/Book2018-000${i + 1}.jpg`)
  }
}

export const galleriaBook2019 = []
for (let i = 0; i < 22; i++) {
  if (i < 9) {
    galleriaBook2019.push(`${pathBooks}2019/Book2019-0000${i + 1}.jpg`)
  } else {
    galleriaBook2019.push(`${pathBooks}2019/Book2019-000${i + 1}.jpg`)
  }
}

export const galleriaFamilyBooks = {
  width: '90vw',
  widthCont: '84vw',
  number: 0,
  galleria: [
    galleriaFamilyBook01,
    galleriaFamilyBook01,
    galleriaFamilyBook02,
    galleriaFamilyBook03,
  ],
  photoIcon: [
    `${pathFamily}Books/FamilyPhoto.jpg`,
    `${pathFamily}Books/FamilyBook01.jpg`,
    `${pathFamily}Books/FamilyBook02.jpg`,
    `${pathFamily}Books/FamilyBook03.jpg`,
  ],
  header: ['2014-2024', '2018', '2016', '2020'],
}

export const galleriaBooks = {
  width: '90vw',
  widthCont: '84vw',
  number: 0,
  galleria: [
    galleriaBook2016,
    galleriaBook2017,
    galleriaBook2018,
    galleriaBook2019,
  ],
  photoIcon: [Сover2016, Сover2017, Сover2018, Сover2019],
  header: ['2016', '2017', '2018', '2019'],
}

export const galleriaReportage = []
export const galleriaReportage170 = []
for (let i = 0; i < 65; i++) {
  if (i < 9) {
    galleriaReportage.push(
      `${pathGalleris}Reportage/1000/Reportage0000${i + 1}.jpg`
    )
    galleriaReportage170.push(
      `${pathGalleris}Reportage/170/Reportage0000${i + 1}.jpg`
    )
  } else {
    galleriaReportage.push(
      `${pathGalleris}Reportage/1000/Reportage000${i + 1}.jpg`
    )
    galleriaReportage170.push(
      `${pathGalleris}Reportage/170/Reportage000${i + 1}.jpg`
    )
  }
}

export const galleriaArtistic = []
export const galleriaArtistic170 = []
for (let i = 0; i < 62; i++) {
  if (i < 9) {
    galleriaArtistic.push(
      `${pathGalleris}Artistic/1000/Artistic0000${i + 1}.jpg`
    )
    galleriaArtistic170.push(
      `${pathGalleris}Artistic/170/Artistic0000${i + 1}.jpg`
    )
  } else {
    galleriaArtistic.push(
      `${pathGalleris}Artistic/1000/Artistic000${i + 1}.jpg`
    )
    galleriaArtistic170.push(
      `${pathGalleris}Artistic/170/Artistic000${i + 1}.jpg`
    )
  }
}

export const galleriaFamily = {
  // width: 740,
  number: 0,
  galleria: [galleriaFamily1000],
  photoIcon: [galleriaFamily170],
  // header: ['Сlub'],
}

export const galleriaClub = {
  // width: 740,
  number: 0,
  galleria: [galleriaClub1000],
  photoIcon: [galleriaClub170],
  // header: ['Сlub'],
}

export const galleriaPhoto = {
  // width: 740,
  number: 0,
  galleria: [
    galleriaReportage,
    galleriaArtistic,
    // galleriaGallerisReportage,
  ],
  photoIcon: [galleriaReportage170, galleriaArtistic170],
  header: ['Reportage', 'Artistic'],
}

export const galleriaSeria2023 = []
for (let i = 0; i < 60; i++) {
  if (i < 9) {
    galleriaSeria2023.push(`${pathSeries}2023/2023seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2023.push(`${pathSeries}2023/2023seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2022 = []
for (let i = 0; i < 54; i++) {
  if (i < 9) {
    galleriaSeria2022.push(`${pathSeries}2022/2022seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2022.push(`${pathSeries}2022/2022seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2017 = []
for (let i = 0; i < 36; i++) {
  if (i < 9) {
    galleriaSeria2017.push(`${pathSeries}2017/2017seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2017.push(`${pathSeries}2017/2017seria000${i + 1}.jpg`)
  }
}

export const galleriaSeria2015 = []
for (let i = 0; i < 20; i++) {
  if (i < 9) {
    galleriaSeria2015.push(`${pathSeries}2015/2015seria0000${i + 1}.jpg`)
  } else {
    galleriaSeria2015.push(`${pathSeries}2015/2015seria000${i + 1}.jpg`)
  }
}

export const galleriaSeries = {
  width: '540px',
  widthCont: '470px',
  number: 0,
  galleria: [
    galleriaSeria2023,
    galleriaSeria2022,
    galleriaSeria2017,
    galleriaSeria2015,
  ],
  photoIcon: [seria2023, seria2022, seria2017, seria2015],
  header: ['2023', '2022', '2017', '2015'],
}

export const galleriaAllBook = {
  width: '90vw',
  widthCont: '84vw',
  number: 0,
  galleria: [galleriaEgyptBook, galleriaFamilyBook01, galleriaBook2019],
  photoIcon: [
    `${pathBooks}Egypt/Egypt-icon.jpg`,
    `${pathFamily}Books/FamilyBook01.jpg`,
    Сover2019,
  ],
  header: ['Travel', 'Family', 'Wedding'],
}
