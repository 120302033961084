import './Family.css'
import React, { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import '../../utils/utils.css'
import { galleriaFamilyBooks } from '../../constants/ImportPhoto'
import { galleriaFamily } from '../../constants/ImportPhoto'
import PhotoGalleria from '../../utils/PhotoGalleria'

export default function Family() {
    const [visible, setVisible] = useState(false)
        const [visible01, setVisible01] = useState(false)
    const [imgOpen01, setimgOpen01] = useState('Long-Poto-Start')
    useEffect(() => {
      setimgOpen01('Long-Poto')
    }, [])
  return (
    <div className="Family">
      <div className="Family_Сell">
        <div className="Header PhotoFamily"></div>
        <div className="Description">
          <h1>Семейная фотосьёмка.</h1>
          <h2>
            Вы хотите запечатлеть важное для вашей семьи событие или может быть
            просто сохранить память о том какие вы сейчас. Если вам нужна помощь
            фотографа свяжитесь со мной, обсудим как лучше организовать
            фотосьемку чтобы получились интересные, неформальные фотографии,
            рассказывающие о вашей семье. И если сочтете уместной мою помощь,
            организуем фотосьёмку в удобном для вас месте и время. Стоимость от
            50 у.е.
          </h2>
        </div>
      </div>
      <div className="card flex justify-content-center">
        <div className="Galleri-menu">
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaFamilyBooks.number = 0
            }}
          >
            <img src={galleriaFamilyBooks.photoIcon[1]} alt="" />
          </div>
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaFamilyBooks.number = 2
            }}
          >
            <img src={galleriaFamilyBooks.photoIcon[2]} alt="" />
          </div>
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaFamilyBooks.number = 3
            }}
          >
            <img src={galleriaFamilyBooks.photoIcon[3]} alt="" />
          </div>
          <div
            className={imgOpen01}
            onClick={() => {
              setVisible(true)
              galleriaFamilyBooks.number = 0
            }}
          >
            <img src={galleriaFamilyBooks.photoIcon[0]} alt="" />
          </div>
        </div>
        <Dialog
          header={galleriaFamilyBooks.header[galleriaFamilyBooks.number]}
          visible={visible}
          style={{ width: '100vw', maxWidth: `${galleriaFamilyBooks.width}` }}
          onHide={() => setVisible(false)}
        >
          {galleriaFamilyBooks.galleria[galleriaFamilyBooks.number].map(
            (item, index) => (
              <div key={index}>
                <img
                  src={item}
                  style={{
                    width: '100vw',
                    maxWidth: `${galleriaFamilyBooks.widthCont}`,
                  }}
                  alt=""
                />
                <p className="mb-5"></p>
              </div>
            )
          )}
        </Dialog>
        <Dialog
          header={galleriaFamilyBooks.header[galleriaFamilyBooks.number]}
          visible={visible01}
          style={{ width: '100vw', maxWidth: `${galleriaFamilyBooks.width}` }}
          onHide={() => setVisible01(false)}
        >
          <PhotoGalleria
            galleria={galleriaFamily.galleria[0]}
            photoIcon={galleriaFamily.photoIcon[0]}
          />
        </Dialog>
      </div>
    </div>
  )
}
