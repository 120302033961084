import './Home.css'
import MenuNew from '../../MenuAll/MenuNew'
import Burger from '../../MenuAll/Burger'
import { languages } from '../../constants/ImportPhoto'
const Home = () => {
  return (
    <div>
      <div className="Home_header">
        <div className="menu">
          <Burger lang={languages} />
          <div className="menuBig">
            <MenuNew lang={languages} />
          </div>
        </div>
      </div>
      {/* <div className="Home_Footer"></div> */}
    </div>
  )
}

export default Home
