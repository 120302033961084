import './Photo.css'
import AccordionGallers from '../../../utils/AccordionGallers'
import { galleriaPhoto } from '../../../constants/ImportPhoto'

const Photo = (props) => {
  return (
    <div className="Wedding_Сell">
      <div className="Photo-header PhotoWedding"></div>
      <div className="Description">
        <h1>Свадебные фотографии.</h1>
        <h2>
          Свадьба — это праздник ваш и близких вам людей. Задача фотографа, не
          вмешиваясь в процесс, искать позицию, ракурс, свет чтобы получить
          серию кадров, рассказывающую о происходящем. Это репортажная сьемка.
          Но как говорят именитые фотографы лучшие репортажные кадры не
          обходятся без постановки. И тогда задача фотографа подсказать как
          лучше организовать постановку кадра. Особенно это касается прогулки,
          где вы главные герои и фотографии будут в первую очередь о двух людях
          и их чувствах. Посмотрите ниже примеры моих репортажных и
          постановочных фото, можем оговорить что вам больше подходит.
        </h2>
      </div>
      <div>
        <AccordionGallers gall={galleriaPhoto} lang={props.lang} />
      </div>
    </div>
  )
}

export default Photo
