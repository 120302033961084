import { Link } from 'react-router-dom'
import React, { useState } from 'react'

var swich = 'linkOff'
// var swich01 = 'linkOff'
// var swich01_01 = 'linkOff'
export default function MenuNew(props) {
  const [visible, setVisible] = useState(1)
  const [visible01, setVisible01] = useState(1)
  const [visible01_01, setVisible01_01] = useState(1)
  console.log(visible, visible01, visible01_01)
  return (
    <div className="MenuNew">
      <div>
        <span
          onClick={() => {
            if (visible < 0) {
              swich = 'linkOff'
              // swich01 = 'linkOff'
              setVisible01(1)
            } else {
              swich = 'linkOn'
              // swich01 = 'linkOff'
              setVisible01(1)
            }
            setVisible(visible * -1)
          }}
        >
          {props.lang[1]}
        </span>
        <menu
          onClick={() => {
            setVisible(visible * -1)
            if (visible < 0) {
              swich = 'linkOff'
              // swich01 = 'linkOff'
            } else {
              swich = 'linkOn'
              // swich01 = 'linkOff'
            }
          }}
        >
          <li className={swich}>
            <Link onClick={() => {}} to="/wedding">
              {props.lang[2]}
            </Link>
          </li>
          <li className={swich}>
            <Link onClick={() => {}} to="/family">
              {props.lang[3]}
            </Link>
          </li>
          <li className={swich}>
            <Link onClick={() => {}} to="/club">
              {props.lang[4]}
            </Link>
          </li>
          {/* <li className={swich}>
            <Link onClick={() => {}} to="/photo">
              {props.lang[11]}
            </Link>
          </li>
          <li className={swich}>
            <Link onClick={() => {}} to="/books">
              {props.lang[12]}
            </Link>
          </li> */}
        </menu>
        <span
          onClick={() => {
            setVisible(1)
            setVisible01(1)
            setVisible01_01(1)
            swich = 'linkOff'
            // swich01 = 'linkOff'
          }}
        >
          <Link to="/books">{props.lang[13]}</Link>
        </span>
        {/* <span
          onClick={() => {
            if (visible01 < 0) {
              swich = 'linkOff'
              swich01 = 'linkOff'
            } else {
              swich = 'linkOff'
              // swich01_01 = 'linkOff'
              swich01 = 'linkOn'
            }
            setVisible01_01(1)
            setVisible01(visible01 * -1)
          }}
        >
          {props.lang[8]}
        </span>
        <menu>
          <li className={swich01}>
            <Link
              onClick={() => {
                setVisible01(visible01 * -1)
                if (visible01 < 0) {
                  swich = 'linkOff'
                  swich01 = 'linkOff'
                } else {
                  swich = 'linkOff'
                  swich01 = 'linkOn'
                }
              }}
              to="/family"
            >
              {props.lang[9]}
            </Link>
          </li>
          <li className={swich01}>
            <Link
              onClick={() => {
                setVisible01(visible01 * -1)
                if (visible01 < 0) {
                  swich = 'linkOff'
                  swich01 = 'linkOff'
                } else {
                  swich = 'linkOff'
                  swich01 = 'linkOn'
                }
              }}
              to="/club"
            >
              {props.lang[10]}
            </Link>
          </li>
        </menu> */}
        <span
          onClick={() => {
            setVisible(1)
            setVisible01(1)
            setVisible01_01(1)
            swich = 'linkOff'
            // swich01 = 'linkOff'
          }}
        >
          <Link to="/contact">{props.lang[5]}</Link>
        </span>
      </div>
    </div>
  )
}
